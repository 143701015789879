import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
let router;
export default router = new Router({
    mode: 'history',
    routes: [
        {  // 前置首页导航栏
            path: '/',
            name: 'indexBar',
            component: () => import('@/views/wallpaper/indexBar.vue'),
            children: [
                {
                    path: '/index',
                    name: 'index',
                    component: () => import('@/views/wallpaper/index.vue'),
                },
                {
                    path: '/type',
                    name: 'type',
                    component: () => import('@/views/wallpaper/type.vue'),
                },
                {
                    path: '/recommended',
                    name: 'recommended',
                    component: ()=> import('@/views/wallpaper/recommended.vue'),
                },
                {
                    path: '/release',
                    name:'release',
                    component: () => import('@/views/wallpaper/release.vue'),
                }


            ]
        },
    ]
})
router.beforeEach((to, from, next) => {
    // to and from are both route objects. must call `next`.
    //  if(to.path=="/"||to.path=="/register"){
    //      next()
    //      return
    //  }
    //
    //
    // let token= null!= window.sessionStorage.getItem("token")
    //  console.log(token+"_++____________");
    //  if(token){
    //      next()
    //      console.log(token+"_++____________");
    //      return
    //  }else{
    //      console.log("瓦尔特人");
    //     next("/")
    //     return
    //  }
    next();
})





